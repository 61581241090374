@import '@codametrix/ui-common/src/styles/_variables';

.exterior-container {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: row;
  flex: 1;
}

.exterior-header {
  background-color: $polar;
  padding: 26px 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1 1 60%;
}

.exterior-icon {
  height: $spacing-7x;
  width: 250px;
}

.context-choice-form,
.service-line-choice-form {
  margin: 0 auto;

  .choose-inline-submit {
    margin-top: -5px;
  }

  .cmx-form.cmx-inline-form select {
    padding: 0.1rem;
    margin-top: 1px;
  }
}

@media only screen and (max-width: 600px) {
  .exterior-body {
    background-image: none;
  }
}

@media only screen and (min-width: 600px) {
  .exterior-body {
    background-color: $polar;
  }
}

@media only screen and (min-width: 1800px) {
  .exterior-body {
    background-size: cover;
  }
}

.exterior-body {
  background-repeat: no-repeat;
  flex: 1 1 40%;
  background-color: $polar;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: $spacing-5x;

  .action-container {
    width: 50%;
    border-radius: 3px;
    background-color: $polar;
    padding: $spacing-5x;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    flex: 0 0 auto;
    margin: 0 8rem 0 8rem;
    max-width: 500px;
  }

  .form-group {
    margin: 0px;
    display: flex;
    flex-direction: column;
    row-gap: $spacing-5x;
  }

  .form-input {
    font-size: $largeText;
    height: 2.4rem;
  }

  .form-row {
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    input:focus {
      border-color: $buttonColor;
      box-shadow: 0 0 0 0.1rem rgba(87, 85, 217, 0.2);
    }
  }

  .form-buttons {
    column-gap: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .cmx-select-list {
    margin-left: $standardRelativePad;
    width: 22rem;
    height: 2.4rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
}

.required-indicator {
  color: $alertTextColor;
  padding-left: $textPad;
}

.error-text {
  color: $errorTextColor;
  font-weight: $accentedWeight;
}

.warning-message {
  @include type-body-2;
  line-height: 1.5;

  .imo-message {
    display: block;
    margin-top: 1rem;
    color: $text-medium-emphasis;
  }
}

.warning-message-container {
  margin-bottom: 5px;
}
