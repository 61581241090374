@import '@codametrix/ui-common/src/styles/_variables';

.icon-upload {
  color: $buttonColor;
}
.dictionary-value-list-container {
  margin-top: 1em;
}

.data-grid .table-head {
  max-height: 50px;
  overflow: hidden;
  min-width: 50px;
  align-items: normal;
  white-space: nowrap;
}

.pageable-list-inner-body {
  overflow-x: scroll;
}

.dropdown {
  margin-right: auto;
  pointer-events: auto;

  :hover {
    cursor: pointer;
  }

  .dropdown-item {
    border: none;
    margin-left: -0.4em;
  }
}

.dictionary-description-field {
  margin-bottom: 5px;
}

.dictionary-purpose-field {
  margin-bottom: 10px;
}
