@import '@codametrix/ui-common/src/styles/_variables';
* {
  box-sizing: border-box;
}

body,
#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  margin: 0;
}

.full-height {
  min-height: 100vh;
}
.min-dimensions {
  min-width: 400px; //Making this quite small for the users that zoom to 150-200% at HFHS
}

.max-dimensions {
  max-width: 100vw;
}

.layout-full {
  display: flex;
  flex-direction: column;
}

.layout-case-list {
  width: 85%;
  margin: 0 auto;
}

.layout-fullscreen-portal {
  z-index: 200;
}

.layout-main {
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 0;

  .side-list-container {
    padding-top: $largerRelativePad;
    .nav-item {
      margin-top: 0px;
    }
    .nav-link {
      padding-left: 11px;
      font-size: $largerText;
      &:hover {
        color: $buttonColor;
      }
      &:active {
        color: $buttonColor;
      }
      &.active {
        &:hover {
          color: $buttonColor;
        }
        &:active {
          color: $buttonColor;
        }
      }
    }
  }
}

.layout-sidebar {
  bottom: 0px;
  left: 0px;
  top: 40px;
  z-index: 200;

  width: 270px;
  background: $sidebarColor;
  transition: width 0.5s;
  position: fixed;
}

.layout-topbar {
  display: flex;
  flex-direction: row;
  left: 0px;
  bottom: 0px;
  position: fixed;
  top: 0px;
  z-index: 200;
  width: 100%;
  height: 40px;
  background: $sidebarColor;
  transition: width 0.5s;
}

.layout-navbar {
  display: flex;
  flex-direction: column;
  left: 0px;
  bottom: 0px;
  position: fixed;
  top: 0px;
  z-index: 200;
  height: 100%;
  transition: width 0.5s;
}

.cmx-application-loading {
  .layout-topbar {
    position: relative;
    z-index: 1;
  }
}

.layout-inner-body {
  flex: 1;
}
